export default {
  baseUrl() {
    return process.env.VUE_APP_API_URL
  },

  apiUrl(uri) {
    return this.baseUrl() + uri
  },

  frontEndUrl() {
    return process.env.APP_URL
  },
}
