import axios from '@/libs/axios'
import apiConfig from '../axios/apiConfig'

const tokenName = 'authToken'

export default {
  getToken() {
    return localStorage.getItem(tokenName)
  },

  login(formData) {
    return axios.post(apiConfig.apiUrl('auth/login'), formData)
  },

  me(authToken) {
    return axios.get(apiConfig.apiUrl('user'), { headers: { Authorization: `Bearer ${authToken}` } })
  },
}
