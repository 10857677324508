<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link
          :to="{name: 'login'}"
          class="brand-logo"
        >
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1" />
        </b-link>

        <!-- form -->
        <validation-observer
          #default="{invalid}"
          tag="form"
        >
          <div
            v-if="loginError.length !== 0"
            class="alert alert-danger p-2"
          >
            <p
              v-for="(error, index) in loginError"
              :key="index"
            >
              {{ error }}
            </p>
          </div>
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login()"
          >
            <!-- email -->
            <b-form-group
              label="البريد الإلكتروني"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group
              label="كلمة المرور"
              label-for="login-password"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="invalid || formSubmitted === true"
            >
              <BSpinner
                v-if="formSubmitted"
                small
                class="mx-1"
              />
              {{ loginStatus || status.login }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>نسيت كلمة المرور ؟ </span>
          <b-link :to="{path:'forgot-password'}">
            <span>إعادة ضبط كلمة المرور</span>
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  localize,
} from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  required,
  email,
} from '@validations'
import {
  togglePasswordVisibility,
} from '@core/mixins/ui/forms'
import auth from '@/auth/auth'
import citiesApi from '@/axios/api/cities'
import categoriesApi from '@/axios/api/categories'

import store from '@/store/index'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',

      formSubmitted: false,
      loginStatus: null,
      status: {
        login: 'تسجيل الدخول',
        fetchingUser: 'تحديث البيانات',
        updatingConfigs: 'ظبط الإعدادات',
      },
      // validation rules
      required,
      email,

      loginError: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  mounted() {
    localize('ar')
  },

  methods: {
    async login() {
      this.formSubmitted = true
      this.loginError = []
      try {
        const response = await auth.login({
          email: this.userEmail,
          password: this.password,
        })
        const { authToken } = response.data

        this.loginStatus = this.status.fetchingUser

        const user = await auth.me(authToken)

        store.commit('user/login', { token: authToken, user: user.data.data })

        this.loginStatus = this.status.updatingConfigs

        const cities = await citiesApi.getCities()
        store.commit('cities/setCities', cities.data)

        const categories = await categoriesApi.getCategories()
        store.commit('categories/setCategories', categories.data.data)

        window.location.href = '/account-settings'
      } catch (error) {
        this.formSubmitted = false
        if (error.response && error.response.status === 400) {
          this.loginError.push('بيانات الدخول غير صحيحة')
        } else {
          console.error(error)
          this.loginError.push('حدث خطأ أثناء تسجيل الدخول')
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.brand-logo img {
  max-width: 200px !important;
}
</style>
