<template>
  <img
    :src="logo"
    alt=""
  >
</template>

<script>
const logo = require('@/assets/images/logo/logo-dark.png')

export default {
  data() {
    return {
      logo,
    }
  },
}
</script>
